import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Pipe,
  PipePlaceholder,
  ValveConnect,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  PumpsRowSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

function PumpScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  const PLC1 = props.plcs?.plcs?.[1];
  if (!props.locations?.locations?.[1] || !PLC1) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Main Pumphouse">
            <InfoControlRow>
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(PLC1)}
                label=""
                color={PLCColorRouter(PLC1, theme)}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment1?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
              />
            </InfoControlRow>
            <PumpsRowCustom height="100px">
              <div css={styles.drainsColumn}>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Outcast Drain"
                    capLabelTop="22px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment1?.Other?.ioValues?.outcast_pressure?.value}
                      label={locationEquipment1?.Other?.ioValues?.outcast_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Jennifers Drain"
                    capLabelTop="25px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment1?.Other?.ioValues?.jennifers_run_drain_pressure?.value}
                      label={locationEquipment1?.Other?.ioValues?.jennifers_run_drain_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>

              </div>
              <div css={styles.valveCorner}>
                <ValveConnect
                  reverseValve
                  pipeAbove="right"
                  valveData={{}}
                />
              </div>
              <PipePlaceholder horizontalAbove />
              <div css={styles.valveCorner}>
                <ValveConnect
                  reverseValve
                  pipeAbove="middle"
                  valveData={{}}
                />
              </div>
              <PipePlaceholder horizontalAbove />
              <div css={styles.valveCorner}>
                <ValveConnect
                  reverseValve
                  pipeAbove="middle"
                  valveData={{}}
                />
              </div>
              <PipePlaceholder horizontalAbove />
              <PipePlaceholder horizontalAbove capAfter capLabel="To Oh No" capLabelLeft="-60px" capLabelTop="-25px">
                <Statistic
                  float
                  floatColor={theme.pipeColors.water}
                  border
                  statistic={locationEquipment1?.Other?.ioValues?.ohno_pressure?.value}
                  label={locationEquipment1?.Other?.ioValues?.ohno_pressure?.ioValueType?.units}
                />

              </PipePlaceholder>
            </PumpsRowCustom>
            <PumpsRowSmall>
              <div css={styles.drainsColumn}>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Last Chance Drain"
                    capLabelTop="22px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment1?.Other?.ioValues?.last_chance_drain_pressure?.value}
                      label={locationEquipment1?.Other?.ioValues?.last_chance_drain_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Hayseed Drain"
                    capLabelTop="22px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment1?.Other?.ioValues?.hayseed_drain_pressure?.value}
                      label={locationEquipment1?.Other?.ioValues?.hayseed_drain_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Lower Dutchman Drain"
                    capLabelTop="22px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment1?.Other?.ioValues?.lower_dutchman_drain_pressure?.value}
                      label={locationEquipment1?.Other?.ioValues?.lower_dutchman_drain_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>

              </div>
              <div css={styles.valveCorner}>
                <PipePlaceholder pipeBelow="right" minWidth="0px" />
              </div>
              <ValveConnect
                reverseValve
                pipeAbove="right"
                pipeBelow="left"
                connectBelow
                valveData={{}}
              />
              <div css={styles.valveCorner}>
                <PipePlaceholder pipeBelow="right" pipeAbove="middle" minWidth="0px" />
              </div>
              <ValveConnect
                reverseValve
                pipeAbove="middle"
                pipeBelow="left"
                connectBelow
                valveData={{}}
              />
              <div css={styles.valveCorner}>
                <PipePlaceholder pipeBelow="right" pipeAbove="middle" minWidth="0px" />
              </div>
              <ValveConnect
                reverseValve
                pipeAbove="middle"
                pipeBelow="left"
                connectBelow
                valveData={{}}
              />
              <PipePlaceholder horizontalAbove capAfter capLabel="To Needle" capLabelLeft="-65px" capLabelTop="-25px">
                <Statistic
                  float
                  floatColor={theme.pipeColors.water}
                  border
                  statistic={locationEquipment1?.Other?.ioValues?.needle_pressure?.value}
                  label={locationEquipment1?.Other?.ioValues?.needle_pressure?.ioValueType?.units}
                />

              </PipePlaceholder>
            </PumpsRowSmall>
            <PumpsRowCustom height="200px">
              <PipePlaceholder horizontalBelow
                capBefore
                capLabel="From Mirror Lake"
                capLabelTop="-25px"
                capLabelLeft="10px"
              >
                <Statistic
                  float
                  floatColor={theme.pipeColors.water}
                  border
                  statistic={locationEquipment1?.Other?.ioValues?.suction_water_pressure?.value}
                  label={locationEquipment1?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units}
                />

              </PipePlaceholder>
              <div css={styles.valveCorner}>
                <PipePlaceholder horizontalBelow minWidth="0px" />
              </div>
              <Pump
                psi
                percent
                pipeBelow="middle"
                setIOValue={props.setIOValue}
                pumpData={{
                  ...locationEquipment1.P1,
                  name: 'P4',
                  ioValues: {
                    ...locationEquipment1.P1.ioValues,
                    temperature: useCelsius ? locationEquipment1?.P1?.ioValues?.seal_temperature_alternate : locationEquipment1?.P1?.ioValues?.seal_temperature,
                  },
                }}
                otherData={{
                  ...locationEquipment1.Other,
                  ioValues: {
                    ...locationEquipment1.Other.ioValues,
                    discharge_pressure: locationEquipment1?.P1?.ioValues?.discharge_pressure,
                  },

                }}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  dischargePressure: 'discharge_pressure',
                  // This is confusing but we use this key with the custom formatted pumpData up top
                  // so that we can have appropriate precision on our suction temperature
                  speed: 'temperature',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment1?.P1?.ioValues?.high_seal_temperature,
                  locationEquipment1?.P1?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P1?.ioValues?.current?.value,
                    label: locationEquipment1?.P1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P1?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P1?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <div css={styles.valveCorner}>
                <PipePlaceholder horizontalBelow minWidth="0px" />
              </div>
              <Pump
                psi
                percent
                pipeBelow="middle"
                setIOValue={props.setIOValue}
                pumpData={{
                  ...locationEquipment1.P2,
                  name: 'P5',
                  ioValues: {
                    ...locationEquipment1.P2.ioValues,
                    temperature: useCelsius ? locationEquipment1?.P2?.ioValues?.seal_temperature_alternate : locationEquipment1?.P2?.ioValues?.seal_temperature,
                  },
                }}
                otherData={{
                  ...locationEquipment1.Other,
                  ioValues: {
                    ...locationEquipment1.Other.ioValues,
                    discharge_pressure: locationEquipment1?.P2?.ioValues?.discharge_pressure,
                  },

                }}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  dischargePressure: 'discharge_pressure',
                  // This is confusing but we use this key with the custom formatted pumpData up top
                  // so that we can have appropriate precision on our suction temperature
                  speed: 'temperature',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment1?.P2?.ioValues?.high_seal_temperature,
                  locationEquipment1?.P2?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P2?.ioValues?.current?.value,
                    label: locationEquipment1?.P2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P2?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <div css={styles.valveCorner}>
                <PipePlaceholder horizontalBelow minWidth="0px" />
              </div>
              <Pump
                psi
                percent
                pipeBelow="left"
                setIOValue={props.setIOValue}
                pumpData={{
                  ...locationEquipment1.P3,
                  name: 'P6',
                  ioValues: {
                    ...locationEquipment1.P3.ioValues,
                    temperature: useCelsius ? locationEquipment1?.P3?.ioValues?.seal_temperature_alternate : locationEquipment1?.P3?.ioValues?.seal_temperature,
                  },
                }}
                otherData={{
                  ...locationEquipment1.Other,
                  ioValues: {
                    ...locationEquipment1.Other.ioValues,
                    discharge_pressure: locationEquipment1?.P3?.ioValues?.discharge_pressure,
                  },

                }}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  dischargePressure: 'discharge_pressure',
                  // This is confusing but we use this key with the custom formatted pumpData up top
                  // so that we can have appropriate precision on our suction temperature
                  speed: 'temperature',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment1?.P3?.ioValues?.high_seal_temperature,
                  locationEquipment1?.P3?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P3?.ioValues?.current?.value,
                    label: locationEquipment1?.P3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P3?.ioValues?.run_time?.value,
                    label: locationEquipment1?.P3?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder hidden />
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    drain: css`
      display: flex;
      height: 50px;
      flex: 1;
      width: 150px;
  `,
    drainsColumn: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      align-items: flex-start;
  `,
    valveCorner: css`
      display: flex;
      height: 100%;
      width: 75px;
  `,
  };
};

PumpScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

PumpScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PumpScreen);
